var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "m-1 mr-4 px-0 proposta" },
        [
          _c("breadcrumb", {
            attrs: {
              title: "Proposta",
              actualPage: "Nova proposta",
              previousPage: "propostas",
              previousPageTitle: "Lista de propostas",
            },
          }),
        ],
        1
      ),
      [
        _c(
          "form-wizard",
          {
            staticClass: "proposta",
            attrs: {
              color: "rgba(var(--vs-warning), 1)",
              title: null,
              subtitle: null,
              finishButtonText: "Submit",
            },
            on: {
              "on-complete": _vm.formSubmitted,
              "on-change": _vm.handleChange,
            },
            scopedSlots: _vm._u([
              {
                key: "footer",
                fn: function (props) {
                  return [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "wizard-footer-left" },
                        [
                          props.activeTabIndex > 0
                            ? _c(
                                "vs-button",
                                {
                                  attrs: { color: "primary" },
                                  nativeOn: {
                                    click: function ($event) {
                                      props.prevTab(),
                                        _vm.enviarEventoVoltarAppInsights(props)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-semibold pr-2 pl-2" },
                                    [_vm._v("Voltar")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "wizard-footer-right" },
                        [
                          !props.isLastStep &&
                          !_vm.tomadorDaProposta.IsNominatedToOtherBroker &&
                          props.activeTabIndex !== 2
                            ? _c(
                                "vs-button",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.disableProsseguirButton(props),
                                    color: "primary",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      props.nextTab(),
                                        _vm.enviarEventoProsseguirAppInsights(
                                          props
                                        )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-semibold pr-2 pl-2" },
                                    [
                                      _vm._v(
                                        "\n                Prosseguir\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          },
          [
            _vm.podeExibirTagCanalCorretorMapfre
              ? _c("TagCanalCorretorMapfre", {
                  staticClass: "mb-6",
                  attrs: {
                    tipoCanalCorretora: _vm.tipoCanalCorretora,
                    view: "proposta",
                  },
                })
              : _vm._e(),
            _c(
              "tab-content",
              {
                staticClass: "mb-8 mt-8",
                attrs: { title: "Dados do tomador" },
              },
              [
                _c("dados-tomador", {
                  attrs: {
                    canRequestLimitsAndRatesReview:
                      _vm.tomadorDaProposta.CanRequestLimitsAndRatesReview,
                  },
                }),
              ],
              1
            ),
            _c(
              "tab-content",
              {
                staticClass: "mb-6 mt-6",
                attrs: { title: "Dados do segurado" },
              },
              [
                _vm.tomadorDaProposta.CpfCnpj
                  ? _c("dados-segurado", {
                      attrs: {
                        cnpjTomador: _vm.tomadorDaProposta.CpfCnpj,
                        uniqueId: _vm.tomadorDaProposta.UniqueId,
                        idTomador: _vm.tomadorDaProposta.Id,
                        podeHabilitarCadastroNomeSocial:
                          _vm.podeHabilitarCadastroNomeSocial,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "tab-content",
              { staticClass: "mb-6 mt-6", attrs: { title: "Dados do risco" } },
              [
                _vm.seguradoDaProposta.CpfCnpj
                  ? _c("dados-risco", {
                      attrs: {
                        seguradoUniqueId: _vm.seguradoDaProposta.UniqueId,
                        seguradoId: _vm.seguradoDaProposta.Id,
                        idTomador: _vm.tomadorDaProposta.Id,
                        tomadorUniqueId: _vm.tomadorDaProposta.UniqueId,
                        tipoCanalCorretora: _vm.tipoCanalCorretora,
                        permissoesCocorretagemMapfre:
                          _vm.permissoesCocorretagemMapfre,
                        permissoesCosseguroMapfre:
                          _vm.permissoesCosseguroMapfre,
                        podeHabilitarCadastroNomeSocialBeneficiario:
                          _vm.podeHabilitarCadastroNomeSocialBeneficiario,
                      },
                    })
                  : _c("div", [
                      _c("h3", { staticClass: "pl-10" }, [
                        _vm._v("Selecione um segurado."),
                      ]),
                    ]),
              ],
              1
            ),
            _c("tab-content", {
              staticClass: "mb-6 mt-6",
              attrs: { title: "Dados financeiros" },
            }),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }